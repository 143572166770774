<div class="tkg-title-group">
  <p class="tkg-title">{{ "Business_model.limite_schedule" | translate:{Default: 'Limite de Agendamento'} }}</p>
  <button
    mat-button
    mat-stroked-button
    color="primary"
    class="tkg-new-btn"
    (click)="addEdit()"
  >
  {{ "Business_model.new" | translate:{Default: 'Novo'} }}
  </button>
</div>
<div class="tkg-description">{{ "Messages.limite_schedule" | translate:
  {Default: 'Você pode limitar o número de agendamentos diários, definindo restrições por operação, sub-operação, tipo de veículo ou grupo de usuário.'} }}
</div>
<div class="tkg-table-container">
<table class="tkg-table">
  <thead>
    <tr>
      <!-- <th>Cliente</th> -->
      <th *ngFor="let col of columns">{{ col.label }}<br /></th>
      <th style="width: 80px; text-align: center">{{ "Business_model.actions" | translate:{Default: 'Ações'} }}<br /></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of limits">
      <td *ngFor="let col of columns">
        <ng-container *ngIf="col.key === 'groups'">
          {{ showGroupName(item?.groups) }}
        </ng-container>
        <ng-container *ngIf="col.key !== 'groups'">
          {{ item[col.key] }}
        </ng-container>
      </td>
      <td>
        <button mat-icon-button color="primary" (click)="addEdit(item)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button color="warn" (click)="delete(item.id)">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </td>
    </tr>
    <tr *ngIf="limits.length === 0">
      <td colspan="100%">
        <p class="no-record">{{ "Messages.no_register" | translate:{Default: "Nenhum registro encontrado!"} }}</p>
      </td>
    </tr>
  </tbody>
</table>
</div>
