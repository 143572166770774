<h3 class="mat-align-center title">{{ "Business_model.automation_configuration" | translate:{Default: "Configuração de Automação"} }}
</h3>
<div mat-dialog-content>
  <div class="form-container">
    <form [formGroup]="form">
      <mat-form-field appearance="outline">
        <mat-label>{{ "Business_model.type_automation" | translate:{Default: "Tipo de Automação"} }}</mat-label>
        <mat-select [placeholder]="'Messages.choose_type' | translate:{Default: 'Escolha o tipo de Automação'}"
          formControlName="type_automation"
          [disabled]="form.get('automate_schedule_to_no_show')?.value || form.get('automate_schedule_to_delayed')?.value">
          <mat-option value="no_show" *ngIf="!optionNoShow">Não Comparecimento Automático</mat-option>
          <mat-option value="delayed" *ngIf="!optionDelayed">Atrasado Automático</mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container *ngIf="form.get('type_automation')?.value === 'no_show'">
        <mat-form-field appearance="outline">
          <mat-label>{{ "Business_model.automation_time" | translate:{Default: "Tempo (Em minutos)"} }}</mat-label>
          <input type="number" matInput formControlName="automate_schedule_to_no_show_after"
            placeholder="{{ 'Business_model.limite_automation' | translate:{Default: 'Para Ativação da Automação'} }}"
            required="form.get('type_automation')?.value === 'no_show'">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "Business_model.label_activation_status" | translate:{Default: "Status de Ativação"}
            }}</mat-label>
          <mat-select multiple [placeholder]="'Business_model.label_activation_status' |
            translate:{Default: 'Selecione os Status de Ativação'}"
            formControlName="automate_schedule_to_no_show_when_status"
            required="form.get('type_automation')?.value === 'no_show'">
            <mat-option [value]="item.id" *ngFor="let item of listStatus">{{item.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "Business_model.label_destination_status" |
            translate:{Default: "Status do não comparecimento"} }}</mat-label>
          <mat-select
            [placeholder]="'Business_model.label_destination_status' | translate:{Default:'Selecione o status do não comparecimento'}"
            formControlName="automate_schedule_to_no_show_set_status"
            required="form.get('type_automation')?.value === 'no_show'">
            <mat-option [value]="item.id" *ngFor="let item of listStatus">{{item.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-checkbox formControlName="automate_schedule_to_no_show"
          required="form.get('type_automation')?.value === 'no_show'">
          {{ "Business_model.active_no_show" | translate:{Default: 'Ativar Não Comparecimento Automático'} }}
        </mat-checkbox>
      </ng-container>

      <ng-container *ngIf="form.get('type_automation')?.value === 'delayed'">
        <mat-form-field appearance="outline">
          <mat-label>{{ "Business_model.automation_time" | translate:{Default: "Tempo (Em minutos) Para Ativação da
            Automação"} }}</mat-label>
          <input type="number" matInput formControlName="automate_schedule_to_delayed_after"
            placeholder="{{ 'Business_model.limite_automation' | translate:{Default: 'Tempo (Em minutos) Para Ativação da Automação'} }}"
            required="form.get('type_automation')?.value === 'delayed'">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "Business_model.label_activation_status" | translate:{Default: "Selecione os Status de
            Ativação"} }}</mat-label>
          <mat-select multiple [placeholder]="'Business_model.label_activation_status' |
            translate:{Default: 'Status de Ativação'}" formControlName="automate_schedule_to_delayed_when_status"
            required="form.get('type_automation')?.value === 'delayed">
            <mat-option [value]="item.id" *ngFor="let item of listStatus">{{item.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "Business_model.label_destination_status" |
            translate:{Default: "Status do não comparecimento"} }}</mat-label>
          <mat-select
            [placeholder]="'Business_model.label_destination_status' | translate:{Default:'Selecione o status do não comparecimento'}"
            formControlName="automate_schedule_to_delayed_set_status"
            required="form.get('type_automation')?.value === 'delayed'">
            <mat-option [value]="item.id" *ngFor="let item of listStatus">{{item.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-checkbox formControlName="automate_schedule_to_delayed"
          required="form.get('type_automation')?.value === 'delayed'">
          {{ "Business_model.active_delayed" | translate:{Default: 'Ativar Atraso Automático'} }}
        </mat-checkbox>
      </ng-container>
    </form>
  </div>

  <div mat-dialog-actions class="tkg-dialog-actions-end">
    <button mat-stroked-button color="warn" (click)="close()">{{ "Business_model.cancel" | translate:{Default:
      "Cancelar"} }}</button>
    <button mat-flat-button color="primary" (click)="onSubmit()" [disabled]="!form.valid">{{ "Business_model.save" |
      translate:{Default: "Salvar"} }}</button>
  </div>
</div>
