import { Component, Input } from '@angular/core';
import { VehicleTypeService } from 'src/app/account/shared/vehicle-type.service';
import { FieldType } from '@ngx-formly/core';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'formly-select-vehicle-type',
  templateUrl: './formly-select-vehicle-type.component.html',
  styleUrls: ['./formly-select-vehicle-type.component.scss']
})

export class FormlySelectVehicleTypeComponent extends FieldType<any> {
  constructor(private vehicleTypeService: VehicleTypeService) {
    super();
  }

  list: any[] = [];
  
  ngOnInit() {

    forkJoin([this.vehicleTypeList()]).subscribe(
      (results) => {
        this.list = results[0];
        
        if (this.form.value.vehicle_type !== undefined && this.form.value.vehicle_type !==  null) {
          this.formControl.setValue(this.formControl.defaultValue);
        }

        if (this.formControl.defaultValue) {
          this.form.value.vehicle_type = this.formControl.defaultValue;
          this.formControl.setValue(this.formControl.defaultValue);
        }
      },
      (error) => {
        console.error('Erro ao buscar os tipos de veículos:', error);
      }
    );
  }

  vehicleTypeList() {
    return this.vehicleTypeService.vehicleTypeList();
  }
}
