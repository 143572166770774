<h3 class="mat-align-center title">
    {{
      "Business.model_schedule_window"
        | translate: { Default: "Janela de Agendamento" }
    }}
  </h3>
  <div *ngIf="validationMsgs.length > 0" class="alert-denied-list">
    <h4>
      <b>{{ "Business_model.attention" | translate: { Default: "Atenção" } }}</b>
    </h4>
    <p *ngFor="let validate of validationMsgs">{{ validate }}</p>
  </div>
  <div mat-dialog-content>
    <div class="form-container">
      <form [formGroup]="form">
        <mat-form-field appearance="outline" [style.display]="departments.length == 1 ? 'none' : 'show'">
          <mat-label>{{ "Business_model.client" | translate:{Default: "Cliente"} }}</mat-label>
          <mat-select [placeholder]="'Business_model.choose_client' | translate:{Default: 'Escolha o Cliente'}" formControlName="department_id" (selectionChange)="departmentChange()">
            <mat-option [value]="item.id" *ngFor="let item of departments">{{item.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Doca</mat-label>
            <mat-select
              placeholder="Escolha a doca"
              formControlName="dock_id"
            >
              <mat-option
                *ngFor="let dock of docks"
                [value]="dock.id"
                >{{ dock.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            "Business_model.operation" | translate: { Default: "Operação" }
          }}</mat-label>
          <mat-select
            [placeholder]="
              'Messages.choose_operation'
                | translate: { Default: 'Escolha a operação' }
            "
            formControlName="operation_id"
            [disabled]="nonEditableField"
            (selectionChange)="operationChange()"
          >
            <mat-option
              [value]="item.id"
              *ngFor="let item of operationsFiltered"
              >{{ item.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="showSuboperation">
          <mat-label>{{ "Business_model.suboperation" | translate:{Default: "Suboperação"} }}</mat-label>
          <mat-select [placeholder]="'Messages.choose_suboperation' | translate:{Default: 'Escolha a suboperação'}" formControlName="suboperation_id" [disabled]="nonEditableField">
            <mat-option [value]="item.id" *ngFor="let item of suboperationsFiltered">{{item.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Faixa de Peso</mat-label>
          <mat-select
            placeholder="Escolha a faixa de peso"
            formControlName="weight_range_id"
          >
            <mat-option
              *ngFor="let weight of weightRangeList"
              [value]="weight.id"
              >{{ weight.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="showDuration">
            <mat-label>{{
              "Business_model.duration" | translate: { Default: "Duração" }
            }}</mat-label>
            <mat-select
              [placeholder]="
                'Messages.choose_duration'
                  | translate: { Default: 'Escolha a duração' }
              "
              formControlName="duration_id"
            >
              <mat-option
                [value]="item.id"
                *ngFor="let item of durations"
                >{{ item.duration }} mins</mat-option
              >
            </mat-select>
          </mat-form-field>
      </form>
    </div>
  
    <div mat-dialog-actions class="tkg-dialog-actions-end">
      <button mat-stroked-button color="warn" (click)="close()">
        {{ "Business_model.cancel" | translate: { Default: "Cancelar" } }}
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="onSubmit()"
        [disabled]="!form.valid"
      >
        {{ "Business_model.save" | translate: { Default: "Salvar" } }}
      </button>
    </div>
  </div>
  