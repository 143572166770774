<div class="tkg-title-group">
  <p class="tkg-title">{{ "Business_model.automation_title" | translate: {Default: "Automação de Status"} }}</p>
  <button
    [disabled]="disabledButton"
    mat-button mat-stroked-button color="primary" class="tkg-new-btn" (click)="addEdit()">{{ "Business_model.new"
    | translate: {Default: "Novo"} }}</button>
</div>
<div class="tkg-description">{{ "Messages.automation"
  | translate: {Default: "Defina as configurações de automação de status. Obs: Para que a automação funcione corretamente é necessário fazer Logout no sistema."} }}</div>
<table class="tkg-table">
  <thead>
    <tr>
      <th>{{ "Business_model.type" | translate: {Default: "Tipo"} }}<br /></th>
      <th>{{ "Business_model.active" | translate: {Default: "Ativo"} }}<br /></th>
      <th>{{ "Business_model.activation_status" | translate: {Default: "Status de Ativação"} }}<br /></th>
      <th>{{ "Business_model.destination_status" | translate: {Default: "Status Destino"} }}<br /></th>
      <th>{{ "Business_model.automation_time" | translate: {Default: "Tempo (Em Minutos)"} }}<br /></th>
      <th style="width: 80px; text-align: center;">{{ "Business_model.actions" | translate: {Default: "Ações"} }}<br />
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="windows.environment?.automate_schedule_to_no_show">
      <td>Não Comparecimento</td>
      <td>{{windows.environment.automate_schedule_to_no_show == true ? 'Sim' : 'Não'}}</td>
      <td>{{this.listStatusName.automate_schedule_to_no_show_when_status}}</td>
      <td>{{this.listStatusName.automate_schedule_to_no_show_set_status}}</td>
      <td>{{windows.environment.automate_schedule_to_no_show_after}}</td>
      <td>
        <button mat-icon-button color="primary" (click)="addEdit('no_show')">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button color="warn" (click)="delete('no_show')">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </td>
    </tr>
    <tr *ngIf="windows.environment?.automate_schedule_to_delayed">
      <td>Atrasado</td>
      <td>{{windows.environment.automate_schedule_to_delayed == true ? 'Sim' : 'Não'}}</td>
      <td>{{this.listStatusName.automate_schedule_to_delayed_when_status}}</td>
      <td>{{this.listStatusName.automate_schedule_to_delayed_set_status}}</td>
      <td>{{windows.environment.automate_schedule_to_delayed_after}}</td>
      <td>
        <button mat-icon-button color="primary" (click)="addEdit('delayed')">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button color="warn" (click)="delete('delayed')">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </td>
    </tr>
    <tr *ngIf="isEmpty">
      <td colspan="100%">
        <p class="no-record">{{ "Messages.no_register" | translate: {Default: "Nenhum registro encontrado!"} }}</p>
      </td>
    </tr>

  </tbody>
</table>
