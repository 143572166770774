import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Driver } from '../drivers.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class DriversService {
  translateSource: any;
  constructor(
    private http: HttpClient,
    private translate: TranslateService,
  ) {
    translate.get('driver.checkin').subscribe((res: string) => {
      this.translateSource = res;
    });
  }

  index(options?: any): Observable<any> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/drivers`;

    return this.http.get<Driver>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  getDrivers(options?: any): Observable<any[]> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/drivers`;

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response.data || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  show(id: number): Observable<any> {
    const url = `${environment.apiUrl}/drivers/${id}`;

    return this.http.get<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error"))
    );
  }

  showViaCpf(options?: any): Observable<Driver> {

    const params = new HttpParams({ fromObject: options })

    const url = `${environment.apiUrl}/drivers-by-cpf`;

    return this.http.get<Driver>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  getTruckerDrivers(options?: any): Observable<Driver> {

    const params = new HttpParams({ fromObject: options })

    const url = `${environment.apiUrl}/trucker-drivers`;

    return this.http.get<Driver>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  getUnlinkedDrivers(options?: any): Observable<Driver[]> {

    const params = new HttpParams({ fromObject: options })

    const url = `${environment.apiUrl}/unlinked-carts`;

    return this.http.get<Driver[]>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  changeStatus(data: any) {
    const url = `${environment.apiUrl}/drivers-status`;

    return this.http.put(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  update(data: any, id: string, params?: any): Observable<Driver> {
    const url = `${environment.apiUrl}/drivers/${id}`;
    const options = { params: params };

    return this.http.put<Driver>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  updateDriverPhotos(data: any, id: string, params?: any): Observable<Driver> {
    const url = `${environment.apiUrl}/photo/${id}`;
    const options = { params: params };

    return this.http.put<Driver>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  create(data: any, params?: any): Observable<Driver> {
    const url = `${environment.apiUrl}/drivers`;
    const options = { params: params };

    return this.http.post<Driver>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  createApp(data: any, params?: any): Observable<Driver> {
    const url = `${environment.apiUrl}/drivers-app`;
    const options = { params: params };

    return this.http.post<Driver>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  destroy(id: string, params?: any) {
    const url = `${environment.apiUrl}/drivers/${id}`;
    const options = { params: params };

    return this.http.delete(url, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }


  history(options?: any) {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'updated_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/history`;

    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }


  showStatus(status: any) {
    return this.translateSource.status[status];
  }

  getValetsDrivers(options?: any): Observable<any> {
    const url = `${environment.apiUrl}/all-drivers-valet`;
    const httpParams = Object.assign({}, options);
    const params = new HttpParams({ fromObject: httpParams });

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error")),
    );
  }
}
