import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { forkJoin, Observable } from 'rxjs';
import { RoutesService } from '../../routes.service';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'formly-select-route',
  templateUrl: './formly-select-route.component.html',
  styleUrls: ['./formly-select-route.component.scss']
})
export class FormlySelectRouteComponent extends FieldType<any> {
  list: any[] = []; // Lista completa da API
  filteredList: any[] = []; // Lista filtrada para exibição no autocomplete

  constructor(private routesService: RoutesService) {
    super();
  }

  ngOnInit() {
    this.getRoutes().subscribe(
      (results) => {
        this.list = results;
        this.filteredList = this.list.slice(0, 5); // Exibe apenas os primeiros 5 registros inicialmente

        // Verifica se há um route_id no form e atribui o objeto correspondente
        if (this.form.value.route_id !== undefined && this.form.value.route_id !== null) {
          const selectedRoute = this.list.find(route => route.id === this.form.value.route_id);
          if (selectedRoute) {
            this.formControl.setValue(selectedRoute); // Define o objeto correto
          }
        }
      },
      (error) => {
        console.error('Erro ao buscar as rotas:', error);
      }
    );

    // Atualiza a lista filtrada conforme o usuário digita
    this.formControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value))
    ).subscribe((filtered: any[]) => {
      this.filteredList = filtered;
    });
  }

  getRoutes(): Observable<any[]> {
    return this.routesService.routesList();
  }

  displayFn(route: any): string {
    return route ? route.name : ''; // Exibe corretamente o nome da rota
  }

  private filter(value: any): any[] {
    const filterValue = String(value?.name || value || '').toLowerCase(); // Considera tanto objetos quanto strings
    return this.list.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  clear() {
    this.formControl.setValue('');
  }
}
