import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AgendamentoService } from 'src/app/multiagendamento/shared/agendamento.service';
import { SupportDataService } from 'src/app/shared/support-data.service';

@Component({
  selector: 'app-automation-dialolog',
  templateUrl: './automation-dialolog.component.html',
  styleUrls: ['./automation-dialolog.component.scss']
})
export class AutomationDialologComponent implements OnInit {
  form = this.formBuilder.group({
    type_automation: [this.data.type_automation, Validators.required],
    automate_schedule_to_no_show: [this.data.automate_schedule_to_no_show],
    automate_schedule_to_no_show_after: [this.data.automate_schedule_to_no_show_after],
    automate_schedule_to_no_show_when_status: [this.data.automate_schedule_to_no_show_when_status],
    automate_schedule_to_no_show_set_status: [this.data.automate_schedule_to_no_show_set_status],
    automate_schedule_to_delayed: [this.data.automate_schedule_to_delayed],
    automate_schedule_to_delayed_after: [this.data.automate_schedule_to_delayed_after],
    automate_schedule_to_delayed_when_status: [this.data.automate_schedule_to_delayed_when_status],
    automate_schedule_to_delayed_set_status: [this.data.automate_schedule_to_delayed_set_status]
  });
  listStatus: any[] = [];
  optionNoShow: boolean = true;
  optionDelayed: boolean = true;
  validationMsgs: any[] = [];
  disableTypoSelect: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AutomationDialologComponent>,
    public formBuilder: UntypedFormBuilder,
    private supportDataService: SupportDataService,
    private agendamentoService: AgendamentoService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.optionDelayed = data?.optionDelayed;
    this.optionNoShow = data?.optionNoShow;
  }

  ngOnInit() {
    this.getStatus();
    if ( this.form.get('automate_schedule_to_no_show')?.value || this.form.get('automate_schedule_to_delayed')?.value) {
      this.form.get('type_automation')?.disable();
    }
    
    this.form.valueChanges.subscribe((values: any)=>{
      const value = Object.entries(values)
      .filter(([key]) => key !== 'type_automation')
      .some(([, value])=> !value);
      if (value){
        this.disableTypoSelect = true;
      }else{
        this.disableTypoSelect = false;
      }
    })
  }

  close() {
    this.dialogRef.close();
  }

  getStatus() {
    this.supportDataService.scheduleStatus().subscribe((r: any) => {
      this.listStatus = r.data.resource;
      this.cdr.detectChanges();
    });
  }

  onSubmit() {
    const data = {
      [`automate_schedule_to_${this.form.get('type_automation')?.value}`]: this.form.value[`automate_schedule_to_${this.form.get('type_automation')?.value}`],
      [`automate_schedule_to_${this.form.get('type_automation')?.value}_after`]: this.form.value[`automate_schedule_to_${this.form.get('type_automation')?.value}_after`],
      [`automate_schedule_to_${this.form.get('type_automation')?.value}_when_status`]: this.form.value[`automate_schedule_to_${this.form.get('type_automation')?.value}_when_status`],
      [`automate_schedule_to_${this.form.get('type_automation')?.value}_set_status`]: this.form.value[`automate_schedule_to_${this.form.get('type_automation')?.value}_set_status`]
    }
    this.agendamentoService.updateSystemClient(data).subscribe((r: any) => {
      if(r.error) {
        this.validationMsgs = r.msg;
      } else {
        this.close();
      }
    });
  }
}
