import { PlanningComponent } from './planning/planning.component';
import { ScheduleListV1Component } from './schedule-list-v1/schedule-list-v1.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { ScheduleListComponent } from './schedule-list/schedule-list.component';
import { RouteGuard } from '../account/shared/route.guard';
import { PreScheduleListComponent } from './pre-schedule-list/pre-schedule-list.component';
import { DockScheduleComponent } from './dock-schedule/dock-schedule.component';

const reportRoutes: Routes = [
  {
    canActivate: [AccountGuardService, RouteGuard],
    runGuardsAndResolvers: 'always',
    path: 'admin/schedule',
    component: ScheduleListComponent,
    data: {
      title: 'Agenda',
      search: false,
      refresh: false,
      btnDownloadReport: false,
      btnStatusPanel: false
    }
  },
  {
    canActivate: [AccountGuardService, RouteGuard],
    runGuardsAndResolvers: 'always',
    path: 'admin/schedule-v1',
    component: ScheduleListV1Component,
    data: {
          title: 'Agenda',
          search: false,
          refresh: false,
          btnDownloadReport: false,
          btnStatusPanel: false
        }
  },
  {
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    path: 'admin/planning',
    component: PlanningComponent,
    data: {
          title: 'Programação',
          search: false,
          refresh: false,
          btnDownloadReport: false,
          btnStatusPanel: false
        }
  },
  {
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    path: 'admin/pre-schedule',
    component: PreScheduleListComponent,
    data: {
          title: 'Pré-agendamento',
          search: false,
          refresh: false,
          btnDownloadReport: false,
          btnStatusPanel: false
        }
  },
  // {
  //   canActivate: [AccountGuardService],
  //   runGuardsAndResolvers: 'always',
  //   path: 'admin/dock-schedule',
  //   component: DockScheduleComponent,
  //   data: {
  //         title: 'Agendamento de Doca',
  //         search: false,
  //         refresh: false,
  //         btnDownloadReport: false,
  //         btnStatusPanel: false
  //       }
  // },
  {
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    path: 'admin/dock-schedule-dynamic/:page',
    component: DockScheduleComponent,
    data: {
          title: '',
          search: false,
          refresh: false,
          btnDownloadReport: false,
          btnStatusPanel: false
        }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(reportRoutes, { anchorScrolling: 'enabled' }),
  ],
  exports: [
    RouterModule
  ]
})
export class SchedulingRoutingModule {
}
