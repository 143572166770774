import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { MaskUtil } from "../../shared/util/mask.util";
import { MatDialog } from "@angular/material/dialog";
import { DialogActionsComponent } from "../dialog-actions/dialog-actions.component";
import { CarrierService } from "../../carrier/shared/carrier.service";
import { DriversService } from "../../drivers/shared/drivers.service";
import { CheckinService } from "../shared/checkin.service";
import { SupportDataService } from "../../shared/support-data.service";
import { DepartmentService } from "../../shared/department.service";
import { CartService } from "../../cart/shared/cart.service";
import { Carrier } from "../../carrier/carrier";
import {
  debounceTime,
  map,
  startWith,
  switchMap,
  filter,
  tap,
  takeUntil,
} from "rxjs/operators";
import { Subject } from "rxjs";
import { VehicleSearchDialogComponent } from "../vehicle-search-dialog/vehicle-search-dialog.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-checkin-dialog",
  templateUrl: "./checkin-dialog.component.html",
  styleUrls: ["./checkin-dialog.component.scss"],
})
export class CheckinDialogComponent implements OnInit {
  translateSource: any;

  carts: any = [];
  horses: any = [];
  lastboard_cart: any;
  lastboard_horse: any;
  parameters: any = [];
  board_horse_disable!: string;
  board_cart_disable!: string;
  input_disable = false;
  disableBoardCart = false;
  updateCheckin = false;
  firstPage = true;
  showBoardCart = true;
  driverLogged: any;
  checkinPage = false;
  checkinPage2 = false;
  checkinTel = false; //caso a empresa tenha escolhido fazer o checkin utilizando o telefone.
  checkinCpf = false; // quando a empresa optar por fazer o checkin pelo CPF
  showDepartment = false; //quando uma empresa trabalha com mais de um departamento
  search_board_show = false; //ocultando o campo de buscar do board
  search_cart_show = false; //ocultando o campo de buscar do cart
  board_readonly = false; //tornando o campo board apenas de leitura
  cart_readonly = false; //tornando o campo cart apenas de leitura
  registerPage = false;
  updateRegisterPage = false;
  lastPage!: string;
  carriers: Carrier[] = [];
  carrierFilter: UntypedFormControl = new UntypedFormControl();
  searching: boolean = false;
  protected _onDestroy = new Subject<void>();
  operations: any;
  departments: any;
  entry_types!: [];
  disableBtnCheckin = false;
  maskUtil = MaskUtil;

  driverLoginTel = this.formBuilder.group({
    phone: ["", Validators.required],
    password: ["", Validators.required],
  });

  driverLoginCpf = this.formBuilder.group({
    cpf: ["", Validators.required],
    password: ["", Validators.required],
  });

  pause = false;
  filterDepartmentSelected: UntypedFormControl = new UntypedFormControl();
  showOptionsOperations = false;
  user: any;
  checkinType: string = "journey";

  checkin = this.formBuilder.group({
    entry_type: [""],
    operation: ["", Validators.required],
    board_horse: ["", Validators.required],
    board_cart: [""],
    department_id: [""],
  });

  checkinPedido = this.formBuilder.group({
    pedido_compra: ["", Validators.required],
    qtd_ajudantes: [""],
  });

  register = this.formBuilder.group({
    name: ["", Validators.required],
    // photo: ["", Validators.required],
    cnh: ["", Validators.required],
    cpf: ["", Validators.required],
    phone: ["", Validators.required],
    carrier_id: ["", Validators.required],
    password: ["", Validators.required],
    make_active: true,
  });

  updateRegister = this.formBuilder.group({
    name: ["", Validators.required],
    cnh: ["", Validators.required],
    cpf: ["", Validators.required],
    phone: ["", Validators.required],
    carrier_id: [""],
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    public carrierService: CarrierService,
    public driverService: DriversService,
    public checkinService: CheckinService,
    public supportDataService: SupportDataService,
    public departmentService: DepartmentService,
    public cartService: CartService,
    private translate: TranslateService,
  ) {
    const user = JSON.parse(localStorage.getItem("account")!);
    if (user) {
      this.user = user;
    }
    translate.get("Checkin.checkinDialog").subscribe((res: string) => {
      this.translateSource = res;
    });
  }

  ngOnInit() {
    // this.loadCarrier();
    this.getOperations();
    this.getParameters();
    // this.getEntryTypes();

    this.getDepartments();

    if (this.user.department_id.length == 1) {
      this.showOptionsOperations = true;
      this.getOperations(this.user.department_id[0]);
    }

    // Filtro na busca de transportadora
    this.carrierFilter.valueChanges
      .pipe(
        filter((search) => !!search),
        tap(() => (this.searching = true)),
        takeUntil(this._onDestroy),
        startWith({}),
        debounceTime(500),
        switchMap(() => {
          const options = { pageSize: 1000 };

          if (this.carrierFilter.value) {
            Object.assign(options, {
              search: `name:${this.carrierFilter.value}`,
            });
          }

          return this.carrierService.index(options);
        }),
        map((response: any) => response.data),
      )
      .subscribe((data) => (this.carriers = data));
  }

  dialogAction(action: any, message?: any) {
    const dialogRef = this.dialog.open(DialogActionsComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: {
        item: action,
        msg: message,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      this.changePage(undefined, data);
    });
  }

  changePage(lstPage: any, act: any) {
    if (lstPage) {
      this.lastPage = lstPage;
    }
    const user = JSON.parse(localStorage.getItem("account")!);
    //checar qual o tipo de login que a empresa escolheu
    let login = user.system_client.resource.environment.driver_login_type;
    this.checkinType =
      user.system_client.resource.environment.driver_checkin_type || "journey";

    if (login == "cpf") this.checkinCpf = true;
    else this.checkinTel = true;

    switch (act) {
      case "checkinPage":
        this.driverLoginCpf.reset();
        this.driverLoginTel.reset();
        this.checkin.reset();
        this.driverLogged = {};
        this.firstPage = false;
        this.checkinPage = true;
        this.checkinPage2 = false;
        this.registerPage = false;
        break;
      case "checkinPage2":
        // this.firstPage = false;
        // this.checkinPage = false;
        // this.checkinPage2 = true;
        // // this.journeyPage = false;
        // this.registerPage = false;
        this.validateSchedule();
        break;
      case "journeyPage":
        this.firstPage = false;
        this.checkinPage = false;
        this.checkinPage2 = false;
        // this.journeyPage = true;
        this.registerPage = false;
        break;
      case "registerPage":
        this.firstPage = false;
        this.checkinPage = false;
        this.checkinPage2 = false;
        this.registerPage = true;
        break;
      case "updateRegisterPage":
        this.firstPage = false;
        this.checkinPage = false;
        this.checkinPage2 = false;
        this.registerPage = false;
        this.updateRegisterPage = true;
        this.populateDriver();
        break;
      case "firstPage":
        this.firstPage = true;
        this.checkinPage = false;
        this.checkinPage2 = false;
        // this.journeyPage = false;
        this.registerPage = false;
        this.driverLoginCpf.reset();
        this.driverLoginTel.reset();
        this.checkin.reset();
        this.driverLogged = {};
        break;
      case "dismiss":
        this.driverLoginTel.reset();
        this.driverLoginCpf.reset();
        this.checkin.reset();
        this.driverLogged = {};
        this.firstPage = false;
        this.checkinPage = true;
        this.checkinPage2 = false;
        // this.journeyPage = false;
        this.registerPage = false;
        break;

      default:
        break;
    }
  }

  back2Page() {
    this.changePage("", this.lastPage);
  }

  createDriver() {
    const data = {
      resource: this.register.value,
    };
    data.resource.cpf = data.resource.cpf.replace(/[^0-9]+/g, "");
    data.resource.phone = data.resource.phone.replace(/[^0-9]+/g, "");
    data.resource.default_carrier = !!data.resource.carrier_id;
    this.driverService.create(data).subscribe((r: any) => {
      if (r) {
        if (r.error) {
          this.dialogAction("checkinError", r.message);
        } else {
          this.dialogAction("registerOk");
        }
      }
    });
  }

  updateDriver() {
    console.log("Atualiza motorista");
    // const driver = {...this.updateRegister.value, id: this.driverLogged.id}
    console.log(this.updateRegister.value);
    const driver = {
      name: this.updateRegister.value.name,
      document: this.updateRegister.value.cpf,
      default_carrier: this.driverLogged.default_carrier,
      is_valet: this.driverLogged.is_valet,
      active: true,
      resource: {
        name: this.updateRegister.value.name,
        cpf: this.updateRegister.value.cpf,
        cnh: this.updateRegister.value.cnh,
        phone: this.updateRegister.value.phone,
        carrier_id: this.updateRegister.value.carrier_id,
        default_carrier: this.driverLogged.default_carrier,
        changePass: false,
      },
    };

    this.driverService
      .update(driver, this.driverLogged.id)
      .subscribe((r: any) => {
        console.log("Motorista Atualizado");
        this.dialogAction("checkinOk", "Faça seu Check In!");
        this.updateRegisterPage = false;
      });
  }

  checkDriverTel() {
    this.disableBtnCheckin = true;
    const user = JSON.parse(localStorage.getItem("account")!);
    let phone = this.driverLoginTel.value.phone
      .replace("(", "")
      .replace(")", "")
      .replace(" ", "")
      .replace("-", "");
    const data = {
      cpf: null,
      phone: phone,
      password: this.driverLoginTel.value.password,
      system_client_id: user.system_client.id,
    };
    this.checkinService.driverLogin(data).subscribe((r: any) => {
      console.log("driver login");
      console.log(r);
      if (r.status === 200) {
        this.dialogAction("checkinOk", r.message);
        this.driverLogged = r;
        console.log("driverLogged : ", r);
        this.lastboard_cart = r.checkin ? r.checkin.resource.board_cart : "";
        this.lastboard_horse = r.checkin ? r.checkin.resource.board_horse : "";
      } else {
        this.dialogAction("checkinError", r.message);
      }
      this.disableBtnCheckin = false;
    });
  }

  checkDriverCpf() {
    this.disableBtnCheckin = true;
    const user = JSON.parse(localStorage.getItem("account")!);
    let cpf = this.driverLoginCpf.value.cpf
      .replace(".", "")
      .replace(".", "")
      .replace("-", "");
    const data = {
      cpf: cpf,
      phone: null,
      password: this.driverLoginCpf.value.password,
      system_client_id: user.system_client.id,
    };
    this.checkinService.driverLoginCpf(data).subscribe((r: any) => {
      console.log("driver login cpf");
      console.log(r);
      if (r.status === 200) {
        if (r.user_register_expired == true) {
          this.dialogAction(
            "updateRegister",
            "Atualize seu cadastro para continuar!",
          );
        } else {
          this.dialogAction("checkinOk", r.message);
        }

        this.driverLogged = r;
        console.log("driverLogged : ", r);
        this.lastboard_cart = r.checkin ? r.checkin.resource.board_cart : "";
        this.lastboard_horse = r.checkin ? r.checkin.resource.board_horse : "";
      } else {
        this.dialogAction("checkinError", r.message);
      }
      this.disableBtnCheckin = false;
    });
  }

  getDepartments() {
    console.log("departments");
    this.supportDataService.departments().subscribe(
      (r: any) => {
        this.departments = r.data.resource;
        this.departments = this.departments.filter((item: any) => {
          return this.user.department_id.indexOf(item.id) > -1;
        });
      },
      (e: any) => {
        console.log("erro");
      },
    );
  }

  getOperations(department_id?: number) {
    this.supportDataService.operationLoreal().subscribe(
      (r: any) => {
        this.operations = r.data.resource.filter(
          (item: any) => item.visible === true,
        );

        if (!department_id && this.user.department_id.length == 1) {
          department_id = this.user.department_id[0];
        }

        if (department_id) {
          this.operations = this.operations.filter((item: any) => {
            return item.department_id === department_id;
          });
        }
      },
      (e: any) => {
        console.log("erro");
      },
    );
  }

  getEntryTypes() {
    this.supportDataService.entry_type().subscribe(
      (r: any) => {
        this.entry_types = r.data.resource;
        console.log(this.entry_types);
      },
      (e: any) => {
        console.log("erro");
      },
    );
  }

  cartSearchDialog(type: any) {
    console.log("cart search");
    const dialogRef = this.dialog.open(VehicleSearchDialogComponent, {
      panelClass: ["dialog-small"],
      disableClose: false,
      data: {
        type: type,
        carrier_id: this.driverLogged.resource.carrier_id,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      console.log("fechou modal");
      console.log(data);

      if (type === "Cavalo") {
        this.checkin.patchValue({ board_horse: data });
        this.board_horse_disable = data;
      }
      if (type === "Carreta") {
        this.checkin.patchValue({ board_cart: data });
        this.board_cart_disable = data;
      }
    });
  }

  clearPlates() {
    this.checkin.patchValue({ board_horse: "", board_cart: "" });
  }

  populateDriver() {
    console.log("formulário de registro");
    console.log(this.driverLogged);
    this.updateRegister.patchValue({
      name: this.driverLogged.name,
      cpf: this.driverLogged.resource.cpf,
      cnh: this.driverLogged.resource.cnh,
      phone: this.driverLogged.resource.phone,
      carrier_id: this.driverLogged.carrier_id,
    });
  }

  // bring in the parameters according to each client's settings
  // after this step call the "prepareForms".This happens in "changePage"
  getParameters() {
    this.checkinService.paramsClient().subscribe((result: any) => {
      if (result.status === 200) {
        this.parameters = result.parameters;
      } else {
        this.dialogAction("checkinError", result.message);
      }
    });
  }

  validateSchedule() {
    const data = {
      driver: this.driverLogged,
      parameters: this.parameters,
    };
    if (this.parameters && this.parameters.validate_schedule_on == "login") {
      //ocultar os campos de busca caso o parametro allow_valet seja false
      if (this.parameters.allow_valet === false) {
        this.search_board_show = false;
        this.search_cart_show = false;
      } else {
        this.search_board_show = true;
        this.search_cart_show = true;
      }

      //tornar os campos apenas de leitura caso o parametro readonly_checkin seja false
      if (this.parameters.readonly_checkin === false) {
        this.board_readonly = false;
        this.cart_readonly = false;
      } else {
        this.board_readonly = true;
        this.cart_readonly = true;
      }

      this.checkinService.getSchedule(data).subscribe((result: any) => {
        if (result.error === false) {
          // this.search_cart_show = false;
          // if the parameters are filled in and the "validate_schedule_on" is 'login'.
          // Allow checkin only if driver has a schedule.

          this.checkin.patchValue({
            board_horse: result.sheduling.board_horse
              ? result.sheduling.board_horse
              : "",
            board_cart: result.sheduling.board_cart
              ? result.sheduling.board_cart
              : "",
            department_id: result.sheduling.department_id
              ? result.sheduling.department_id
              : "",
            operation: result.sheduling.operation_id
              ? result.sheduling.operation_id
              : null,
          });

          // this.search_board_show = true;
          // this.search_cart_show = true;

          //console.log('aaaa', this.checkin.value);

          //Se placa de carreta estiver vazia
          //É um veiculo com apenas uma placa, então desabilitar
          //O campo placa de carreta
          if (this.checkin.value.board_cart == "") {
            this.showBoardCart = false;
          }

          this.firstPage = false;
          this.checkinPage = false;
          this.checkinPage2 = true;
          // this.journeyPage = false;
          this.registerPage = false;

          //Se for passado um id de departamento, os dados do checkin serão preenchidos automaticamente
          //Chama a função getOperations de novo para que a lista de seleção de operações estejam com as operações do departamento correto
          if (result.sheduling.department_id) {
            this.getOperations(this.checkin.value.department_id);
          }

          this.prepareForms(this);
        } else {
          this.dialogAction("checkinErrorBoard", result.message);
        }
      });
    } else {
      //Parametro que habilita a lupa que busca baús desvinculados durante o processo de checkin
      this.search_board_show = Boolean(this.parameters.use_legacy_cart);
      this.search_cart_show = Boolean(this.parameters.use_legacy_cart);

      this.firstPage = false;
      this.checkinPage = false;
      this.checkinPage2 = true;
      // this.journeyPage = false;
      this.registerPage = false;
      this.prepareForms(this);
    }
  }

  createOrUpdateJourney() {
    console.log("update journey");
    console.log("PASSOU PELAS CONDIÇÕES DE PLACA E ERROS, REALIZAR CHECKIN ->");
    // Se o motorista for valet libera a opção de buscar veículos desvinculados
    // Se motorista comum, segue o fluxo normal de checkin

    const user = JSON.parse(localStorage.getItem("account")!);

    let cpf =
      this.checkinCpf === true
        ? this.driverLoginCpf.value.cpf
            .replace(".", "")
            .replace(".", "")
            .replace("-", "")
        : null;
    let phone =
      this.checkinTel === true
        ? this.driverLoginTel.value.phone
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "")
            .replace("-", "")
        : null;
    let entry_t;
    if (this.driverLogged.resource.valet) {
      entry_t = 2;
    } else {
      entry_t = 0;
    }
    this.checkin.value.entry_type = entry_t;
    let data;

    //console.log('update checkin', this.updateCheckin);

    // Atualiza checkin
    if (this.updateCheckin) {
      data = {
        checkin_id: this.driverLogged.checkin.id,
        resource: {
          entry_type: entry_t,
          board_horse: this.checkin.value.board_horse,
          board_cart:
            this.input_disable === true
              ? this.board_cart_disable
              : this.checkin.value.board_cart,
          operation: this.checkin.value.operation,
          pedido_compra: this.checkinPedido.value.pedido_compra,
          qtd_ajudantes: this.checkinPedido.value.qtd_ajudantes,
        },
      };

      console.log("atualizando checking", data);
      this.checkinService.updateJourney(data).subscribe((r: any) => {
        console.log("update checkin");
        console.log(r);

        let action = "createCheckinOk";
        if (r.error === true) action = "checkinError";

        this.dialogAction(action, r.message);
        this.disableBtnCheckin = false;
      });
    } else {
      //console.log('aqui no else');
      if (this.checkinCpf === true) {
        console.log("cpf");
        data = {
          cpf: cpf,
          phone: phone,
          password: this.driverLoginCpf.value.password,
          system_client_id: user.system_client.id,
          resource: {
            cpf: cpf,
            entry_type: entry_t,
            pedido_compra: this.checkinPedido.value.pedido_compra,
            qtd_ajudantes: this.checkinPedido.value.qtd_ajudantes,
            board_horse: this.checkin.value.board_horse,
            board_cart:
              this.input_disable === true
                ? this.board_cart_disable
                : this.checkin.value.board_cart,
            operation: this.checkin.value.operation,
            department_id: this.checkin.value.department_id
              ? this.checkin.value.department_id
              : this.user.department_id
                ? this.user.department_id[0]
                : null,
            checkin_origem: "web",
          },
        };
      }

      if (this.checkinTel === true) {
        data = {
          cpf: cpf,
          phone: phone,
          password: this.driverLoginTel.value.password,
          system_client_id: user.system_client.id,
          resource: {
            cpf: cpf,
            entry_type: entry_t,
            pedido_compra: this.checkinPedido.value.pedido_compra,
            qtd_ajudantes: this.checkinPedido.value.qtd_ajudantes,
            board_horse: this.checkin.value.board_horse,
            board_cart:
              this.input_disable === true
                ? this.board_cart_disable
                : this.checkin.value.board_cart,
            operation: this.checkin.value.operation,
            department_id: this.checkin.value.department_id
              ? this.checkin.value.department_id
              : this.user.department_id
                ? this.user.department_id[0]
                : null,
            checkin_origem: "web",
          },
        };
      }

      console.log("criando checkin", data);
      this.disableBtnCheckin = true;
      this.checkinService.create(data).subscribe(
        (r: any) => {
          console.log("driver login");
          console.log(r);
          let action = "createCheckinOk";
          if (r.error === true) action = "checkinError";

          this.dialogAction(action, r.message);
          this.disableBtnCheckin = false;
        },
        (error: any) => {
          this.disableBtnCheckin = false;
          this.dialogAction(
            "checkinError",
            "Ocorreu um error durante o processo de check in.",
          );
        },
      );
    }
  }

  prepareForms(dados: any) {
    if (this.driverLogged.checkin && this.driverLogged.checkin !== false) {
      //jogar dentro de uma funcao e qdo for necessario eu chamo
      if (this.driverLogged.resource.valet) {
        console.log("CHECKIN MANOBRISTA DEIXA PASSAR");
        //SE MANOBRISTA + JORNADA ATIVA

        this.checkin.get("board_cart")!.enable();
        this.input_disable = false;

        if (this.driverLogged.checkin) {
          this.checkin.get("board_cart")!.disable();
          this.input_disable = true;
          this.board_cart_disable =
            this.driverLogged.checkin.resource.board_cart;
        }
      }

      this.checkin.reset();
      this.checkinPedido.reset();
      this.updateCheckin = true;
      this.checkin.patchValue({
        operation: this.driverLogged.checkin.resource.operation,
        board_horse: this.driverLogged.checkin.resource.board_horse,
        board_cart: this.driverLogged.checkin.resource.board_cart,
        department_id: this.user.department_id
          ? this.user.department_id[0]
          : null,
      });
      this.checkinPedido.patchValue({
        pedido_compra: this.driverLogged.checkin.resource.pedido_compra || "",
        qtd_ajudantes: this.driverLogged.checkin.resource.qtd_ajudantes || "",
      });
    } else {
      this.updateCheckin = false;
    }
  }
}
