<div class="tkg-dyn-filter">
  <div class="tkg-filter-wrapper">
    <div class="tkg-jorney-dyn-filter">
      <app-web-btn-filters [filterList]="btnFilterList" [origin]="'view-'+ pageParam + '_filters'"
        (updateFilterList)="updateFilterList($event)">
      </app-web-btn-filters>
    </div>
    <div class="tkg-selected-filters-container">
      <div class="tkg-selected-filters" *ngIf="btnFilterList.length">
        <span
          class="tkg-chips tkg-chips-txt mat-chip-with-trailing-icon"
          *ngFor="let item of btnFilterList; index as idx"
          (click)="removeFilterItem(idx)"
        >
          <span class="material-icons tkg-chips-icon"> cancel </span>
          <b class="tkg-chips-filter-name">{{ item.label }}</b>
          <span class="tkg-chips-filter-value">{{ item.value }}</span>
        </span>
      </div>
    </div>
    <div class="tkg-search-din">
      <mat-form-field appearance="outline">
        <mat-label>Buscar</mat-label>
        <input matInput placeholder="Buscar" [formControl]="searchFilter" />
        <mat-icon matSuffix *ngIf="!searchFilter.value">search</mat-icon>
        <mat-icon
          matSuffix
          (click)="clearFilter($event)"
          [matTooltip]="'Business_model.clean_filter' | translate:{Default: 'Limpar pesquisa'}"
          ngClass="app-search-clear"
          *ngIf="searchFilter.value"
          >clear</mat-icon
        >
      </mat-form-field>
    </div>
  </div>
</div>
<app-loading-table *ngIf="showLoadingData"></app-loading-table>
<div class="message" *ngIf="showMessage">
  <p class="tkg-message">{{ message }}</p>
</div>
<app-list-empty *ngIf="showListEmpty"></app-list-empty>
<div class="mat-elevation-z4">
  <app-paginate-sort-table
    *ngIf="!showLoadingData && !showMessage"
    [displayedColumns]="displayedColumns"
    [pageIndex]="page"
    [pageSize]="pageSize"
    [dataSource]="dataSource"
    [totalItems]="totalItems"
    [columnsSchema]="columnsSchema"
    [actions]="action_list"
    (action)="action($event)"
    tableId="report_docks"
    [paginate]="paginate"
    (performOnChangePageOnParent)="performOnChangePage($event)"
    (performSortChangeOnParent)="performSortChange($event)"
  >
  </app-paginate-sort-table>
</div>
<button
  *ngIf="showAddButton"
  mat-mini-fab
  (click)="onClickAddButton()"
  class="mat-fab-bottom-right"
  color="primary"
>
  <mat-icon>add</mat-icon>
</button>
