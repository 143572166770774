import { UserService } from './../../../user/shared/user.service';
import { AgendamentoService } from './../../../multiagendamento/shared/agendamento.service';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { SupportDataService } from 'src/app/shared/support-data.service';
import { format } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-schedule-blocks-dialog',
  templateUrl: './schedule-blocks-dialog.component.html',
  styleUrls: ['./schedule-blocks-dialog.component.scss']
})
export class ScheduleBlocksDialogComponent implements OnInit {
  form = this.formBuilder.group({
    department_id: [this.data.department_id, Validators.required],
    operation_id: [[this.data.operation_id], Validators.required],
    suboperation_id: [],
    start: [this.data.start, Validators.required],
    end: [this.data.end, Validators.required],
    allday: [this.data.allday ? this.data.allday : false],
    block_type: [this.data.block_type],
    day: [[], Validators.required],
    weekday: [this.data.weekday, Validators.required],
    groups:[this.data.groups ? JSON.parse(this.data.groups) : [], Validators.required],
    datepicker:[new Date()],
  });
  departments: any[] = [];
  operations: any[] = [];
  suboperations: any[] = [];
  suboperationsFiltered: any[] = [];
  operationsFiltered: any[] = [];
  groups: any[] = [];
  showSuboperation = false;
  weekDayBlocked!: number[];
  blockedWeekday: any[];
  monthDayBlocked: any[] = [];
  nonEditableField = false;
  account: any;
  msgErrorBlockedDay = false;
  oldDate = false;
  startDatePicker = new Date();

  constructor(
    public dialogRef: MatDialogRef<ScheduleBlocksDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    public changeDetectorRef: ChangeDetectorRef,
    private supportDataService: SupportDataService,
    private agendamentoService: AgendamentoService,
    private userService: UserService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem("account")!);
    this.getDepartments();
    this.getOperations();
    this.getSubOperations();
    this.getGroups();

    if(this.data.block_type == 1) {
      const daysBlockedList = JSON.parse(this.data.day.replace(/'/g, ''));
      this.monthDayBlocked = daysBlockedList
      this.form.value.datepicker.setDate(this.form.value.datepicker.getDate() + 1);
      this.form.get('datepicker')?.setValue(this.form.value.datepicker);

      if (this.monthDayBlocked.some((dateString) => this.PastDate(dateString))) {
        this.oldDate = true;
      } else {
        this.form.get('weekday')!.clearValidators();
        this.form.get('day')!.clearValidators();
      }

    } else if (this.data.block_type == 2) {
      this.form.get('weekday')!.clearValidators();
      this.form.get('day')!.clearValidators();

      const week_blocked = JSON.parse(this.data.weekday.replace(/'/g, ''));
      this.blockedWeekday = week_blocked;
      this.form.get('weekday')!.setValue(week_blocked);
      this.form.get('weekday')!.updateValueAndValidity();
    } else {
      this.form.get('weekday')!.clearValidators();
      this.form.get('day')!.clearValidators();
    }

    if(this.data.allday == true) {
      this.form.get('start')!.setValue(null);
      this.form.get('start')!.clearValidators();
      this.form.get('start')!.updateValueAndValidity();

      this.form.get('end')!.setValue(null);
      this.form.get('end')!.clearValidators();
      this.form.get('end')!.updateValueAndValidity();
    }
  }

  close(){
    this.dialogRef.close();
  }

  getOperations(){
    this.supportDataService.generic({name: 'operation_type'}).subscribe((r: any) => {
      this.operations = r.data.resource;

      this.operations = this.operations.filter(item => item.use_in_scheduling === true);
      this.operationsFiltered = this.operations.filter(item => item.use_in_scheduling === true);

      this.operationsFiltered = r.data.resource.filter((of: any) => {
        return this.departments.find((item: any) => item.id == of.department_id);
      })
      //Filtra as operações e mostra apenas as operações que possuem campo use_in_scheduling como true

      if(this.data.operation_id){
        this.nonEditableField = true;
        this.departmentChange();
      }
    });
  }

  getSubOperations(){
    this.supportDataService.generic({name: 'suboperation_type'}).subscribe((r: any) => {
      this.suboperations = r.data.resource;
      if(this.data.suboperation_id){
        this.nonEditableField = true;
        this.operationChange();
        this.changeAllDayBlock();
      }
    });
  }

  setSuboperationsFilter(data: any){
    this.suboperationsFiltered = [];
    this.form.patchValue({suboperation_id: [""]})

    //const ft = this.suboperations.filter((item: any) => item.operation_id === operation)

    /*   this.operationsFiltered = r.data.resource.filter((of: any) => {
      return this.departments.find((item: any) => item.id == of.department_id);
    }) */

    this.suboperationsFiltered = this.suboperations.filter((sf: any) => {
      return data.find((item: any) => item.operation_id.includes(sf.operation_id) && item.department_id == sf.department_id);
    });

    if(this.suboperationsFiltered.length > 0){
      this.showSuboperation = true;
      this.form.get('suboperation_id')!.setValidators([Validators.required]);
      if(this.data.suboperation_id){
        this.form.patchValue({suboperation_id:[this.data.suboperation_id]});
        this.form.controls.suboperation_id.disable();
      }
      this.form.get('suboperation_id')!.updateValueAndValidity();
    } else {
      this.showSuboperation = false;
      this.form.get('suboperation_id')!.setValue(null);
      this.form.get('suboperation_id')!.clearValidators();
      this.form.get('suboperation_id')!.updateValueAndValidity();
    }
  }

  getDepartments(){
    this.supportDataService.generic({name: 'departments'}).subscribe((r: any) => {
      this.departments = r.data.resource
      this.departments = this.departments.filter((item: any) => {
        return this.account.department_id.indexOf(item.id) > -1;
      })
      if(this.departments.length === 1) {
        this.form.patchValue({department_id: this.departments[0].id})
      } else if(!this.data.id) {
        //Se não possuir data.id, é um novo bloqueio para um cliente com mais de um departamento
        //Então desativa o campo de Operações até que seja informado um Departamento
        this.form.controls.operation_id.disable();
      }
    });
  }

  getGroups(){
    const options = { pageSize: 200 };
    this.userService.userGroups(options).subscribe((r: any) => {
      this.groups = r.data;
    });
  }

  operationChange(){
    if(this.suboperations){
      let operation_id = this.form.get('operation_id')!.value;
      let department_id = this.form.get('department_id')!.value;

      this.setSuboperationsFilter([{department_id: department_id, operation_id: operation_id}]);
    }
  }

  onSubmit() {
    if (this.data.id) {
      this.form.value.operation_id = this.data.operation_id
      this.form.value.suboperation_id = this.data.suboperation_id
    }
    
    const options = {
      id: this.data.id ? this.data.id : null,
      department_id: this.form.value.department_id,
      resource: {
        operation_id: this.form.value.operation_id,
        suboperation_id: this.form.value.suboperation_id,
        start: this.form.value.start,
        end: this.form.value.end,
        block_type: parseInt(this.form.value.block_type),
        allday: this.form.value.allday,
        day: this.form.value.day ? this.monthDayBlocked: null,
        weekday: this.form.value.weekday,
        groups: this.form.value.groups
      }
    }
    if(options.id) {
      this.agendamentoService.updateSchedulingBlock(options).subscribe((r: any) => {
        this.close();
      });
    } else {
      this.agendamentoService.createSchedulingBlock(options).subscribe((r: any) => {
        this.close();
      });
    }

  }

  changeAllDayBlock() {
    let adb;
    adb = this.form.value.allday;

    if(adb == true){
      this.form.get('start')!.setValue(null);
      this.form.get('start')!.clearValidators();
      this.form.get('start')!.updateValueAndValidity();
      this.form.get('end')!.setValue(null);
      this.form.get('end')!.clearValidators();
      this.form.get('end')!.updateValueAndValidity();
    } else {
      this.form.get('start')!.setValidators([Validators.required]);
      this.form.get('start')!.updateValueAndValidity();
      this.form.get('end')!.setValidators([Validators.required]);
      this.form.get('end')!.updateValueAndValidity();
    }
  }

  changeBlockType(){
    const bt = this.form.get('block_type')!.value;

    if(bt == 1) {
      this.form.get('day')!.clearValidators();
      this.form.get('day')!.setValidators([Validators.required]);
      this.form.get('day')!.updateValueAndValidity();
      this.form.get('datepicker')!.setValue(new Date);
      this.form.get('datepicker')!.updateValueAndValidity();
      this.form.get('weekday')!.setValue(null);
      this.form.get('weekday')!.clearValidators();
      this.form.get('weekday')!.updateValueAndValidity();
    }

    if(bt == 2) {
      this.form.get('weekday')!.setValidators([Validators.required]);
      this.form.get('weekday')!.updateValueAndValidity();
      this.form.get('datepicker')!.setValue(null);
      this.form.get('datepicker')!.updateValueAndValidity();
      this.form.get('day')!.setValue(null);
      this.form.get('day')!.clearValidators();
      this.form.get('day')!.updateValueAndValidity();
    }

    if(bt == 3) {
      this.form.get('weekday')!.setValue(null);
      this.form.get('weekday')!.clearValidators();
      this.form.get('weekday')!.updateValueAndValidity();
      this.form.get('day')!.setValue(null);
      this.form.get('day')!.clearValidators();
      this.form.get('day')!.updateValueAndValidity();
    }
  }

  onChangeWeek(weekDayList: []) {
    this.weekDayBlocked = weekDayList.map((weekday) => parseInt(weekday));
    this.form.get('weekday')!.setValue(this.weekDayBlocked);
    this.form.get('weekday')!.updateValueAndValidity();
  }
  // ToDo:
  // 1) Validar se o valor do select end é maior que o valor escolhido no select start

  departmentChange(){
    this.setOperationsFilter(this.form.get('department_id')!.value)
  }

  setOperationsFilter(department: number){
    this.operationsFiltered = [];
    this.form.patchValue({operation_id: [""]})
    const ft = this.operations.filter((item: any) => item.department_id === department)
    this.operationsFiltered = ft;
    //Se não for a edição de um bloqueio, ativa o campo de Operações pois foi informado um Departamento
    if(!this.data.id) {
      this.form.controls.operation_id.enable();
     }

    this.form.patchValue({operation_id: [this.data.operation_id]});
  }

  catchDate() {
    const pickerDate = format(new Date(this.form.value.datepicker), 'yyyy-MM-dd');
    this.msgErrorBlockedDay = false;

    if(this.monthDayBlocked.length > 0) {
      if (this.monthDayBlocked.includes(pickerDate)){
        this.msgErrorBlockedDay = true
      } else {
        this.monthDayBlocked.push(pickerDate);
        this.form.get("day")?.setValue(this.monthDayBlocked);
        this.form.get('day')!.updateValueAndValidity();
      }
    } else {
      this.monthDayBlocked.push(pickerDate);
      this.form.get("day")?.setValue(this.monthDayBlocked);
      this.form.get('day')!.updateValueAndValidity();
    }

  }

  removeDate(date: string) {
    const index = this.monthDayBlocked.indexOf(date);

    if (index >= 0) {
      this.monthDayBlocked.splice(index, 1);
      this.form.get("day")?.setValue(this.monthDayBlocked);
    }

    let today = this.formatDate(new Date());
    const futureDates = this.monthDayBlocked.filter(data => data >= today);

    if (this.monthDayBlocked.length == futureDates.length) {
      this.oldDate = false;
      this.form.get('day')!.clearValidators();
      this.form.get('weekday')!.clearValidators();
      this.form.get("weekday")?.updateValueAndValidity();
    } else {
      this.form.get("weekday")?.setValidators(Validators.required);
    }

    if (this.monthDayBlocked.length === 0) {
      this.form.get("day")?.setValidators(Validators.required);
      this.form.get("day")?.updateValueAndValidity();
    }
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  PastDate(blocked_day: string): boolean {
    const today = this.formatDate(new Date());
    const formattedBlockedDay = blocked_day;
    return formattedBlockedDay < today;
  }
}
