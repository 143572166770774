import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Checkin } from '../checkin.interface';


@Injectable({
  providedIn: 'root'
})
export class CheckinService {
  envData: any;
  constructor(
    private http: HttpClient
  ) {
    this.envData = JSON.parse(localStorage.getItem("account")!);
  }

  index(options?: any): Observable<Checkin> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/waits-checkin`;

    return this.http.get<Checkin>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  indexWithEvents(options?: any): Observable<Checkin> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/waits-checkin-events`;

    return this.http.get<Checkin>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  create(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/waits-webcheckin`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  newCheckin(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/checkins/new`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  driverLogin(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/drivers-web/login`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  driverLoginCpf(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/drivers-app/login-cpf`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  paramsClient(params?: any): Observable<any> {
    const url = `${environment.apiUrl}/system-client-web/params`;
    const options = { params: params };

    return this.http.post<any>(url, {}, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  getSchedule(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/scheduling`;
    //console.log('here url', url);
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  changeStatus(data: any) {
    const url = `${environment.apiUrl}/waits-releases`;

    return this.http.put(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  changeStatusConfirmEnter(data: any) {
    const url = `${environment.apiUrl}/waits-confirm-enter`;

    return this.http.put(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  getLocation(id: number) {
    const url = `${environment.apiUrl}/waits-location/${id}`;
    return this.http.get<Checkin>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );

  }

  getDriverCheckindUpdates() {
    // return this.db.list('driver-dashboard').snapshotChanges();
  }

  getMapsUpdates(fbKey: string) {
    // return this.db.list(`checkin/${fbKey}/maps`).snapshotChanges();
  }

  checkin(options?: any) {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/checkin`;

    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  show(id?: any) {
    
    const url = `${environment.apiUrl}/checkins/${id}`;

    return this.http.get(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  /// Novo checkin dinâmico
  checkins(api_endpoint: string, options?: any) {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/${api_endpoint}`;

    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  updateStatus(data: any) {
    const url = `${environment.apiUrl}/checkins`;

    return this.http.put(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  updateJourney(data: any) {
    const url = `${environment.apiUrl}/checkins/update`;

    return this.http.put(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  updateResource(data: any){
    const url = `${environment.apiUrl}/checkins-update-resource`; 

    return this.http.put(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  updateField(endpoint: any, data: any) {
    const url = `${environment.apiUrl}/${endpoint}`;

    return this.http.put(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  truckerCheckout(data: any) {
    const url = `${environment.apiUrl}/checkins-trucker-checkout/${data.id}`;
    return this.http.get<Checkin>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );

  }

  activateValetMode(checkinId: any) {
    const url = `${environment.apiUrl}/valet/activate-valet-mode/${checkinId}`;
    return this.http.put<any>(url, checkinId).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  deactivateValetMode(checkinId: any) {
    const url = `${environment.apiUrl}/valet/deactivate-valet-mode/${checkinId}`;
    return this.http.put<any>(url, checkinId).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  verifyAvalaibleValetDriver(checkinId: any) {
    const url = `${environment.apiUrl}/valet/drivers-available/${checkinId}`;
    return this.http.get<any>(url, checkinId).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  registerHandling(data: any) {
    const url = `${environment.apiUrl}/valet`;
    return this.http.post<any>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  steps(options?: any) {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/checkins/v2/dynamic`;

    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  schema(options?: any) {

    const params = new HttpParams({ fromObject: options })

    const url = `${environment.apiUrl}/checkins/v2/schema`;

    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  manageBtn(data: any) {
    // console.log('no pai -----');
    // console.log(event);
    const array_status = data.action.active_on_status;
    const deactivate = data.action.deactivated_when;
    const activated = data.action.activated_when;

    const st = array_status?.filter((item: any) => {
      if (data.status === item || item === "*") {
        if (deactivate && data.rowValue && (data.rowValue.resource || { resource: {} })[deactivate.key_has_value])
          return false;

        if (activated && data.rowValue)
          return !!(data.rowValue.resource || { resource: {} })[activated.key_has_value];

        return true

      } else {
        return false;
      }
    });

    if (data.action.component === "unlinkDriver" ) {
      if (data.rowValue.resource.cpf && data.rowValue.resource.driver_id){
        return false
      }
    }

    if (data.action.component === "linkDriver" ) {
      if (!data.rowValue.resource.cpf && !data.rowValue.resource.driver_id){
        return false
      }
    }

    //Condições Manobrista
    if(this.envData.system_client.resource.environment.module_valet) {
      if(data.rowValue.resource.valet_mode_activated === true){
        if(data.rowValue.valet_journey){
          const valetJourneyStatus = <string>data.rowValue.valet_journey.resource.parking_status;
          if(valetJourneyStatus == "started")
            return true;

          if(data.action.component === "valetConfirm")
            return false;

          if(valetJourneyStatus == "waiting")
            return true;

        }

        if(data.action.component === "valetConfirm")
          return false;

        if(data.action.module_valet_action)
            return  !(st && st.length > 0);

        return true;

      }else if(data.action.component === "valetConfirm"){
        return false;
      }
    }

    //Condições engate e desengate
    if (
      data.action.disableUnlink != false &&
      this.envData.system_client.resource.environment.trip_hitch &&
      (data.rowValue.last_trucker_checkin == null ||
      (!data.rowValue.resource.board_cart && !data.rowValue.resource.board_horse) ||
      !data.rowValue.resource.trucker_checkin_id
      ))
     {
      return true;
    }

    if(st) return !(st.length > 0);
    return true;
  }

  checkinItem(id: string, options?: any) {
    const url = `${environment.apiUrl}/checkins/details/${id}`;
    const params = new HttpParams({ fromObject: options })
    return this.http.get<any>(url, {params}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }
}
