import { BusinessPartnerService } from "src/app/account/shared/business-partner.service";
import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";
import { debounceTime, startWith, tap } from "rxjs/operators";

@Component({
  selector: 'app-formly-search-bp-driver',
  templateUrl: './formly-search-bp-driver.component.html',
  styleUrls: ['./formly-search-bp-driver.component.scss']
})
export class FormlySearchBpDriverComponent extends FieldType<any> implements OnInit {
  @ViewChild('inputElement') inputElement!: ElementRef;
  _formControl = new FormControl();
    listOptions: any = [];
    searching = false;
    partners: any = [];
    bpTypeList: any = [];
    selectedItem = false;
    ngModule: any;
    isFirstClick = true;
  
    constructor(
      private businessPartnerService: BusinessPartnerService,
      private cdr: ChangeDetectorRef,
    ) {
      super();
    }
  
  ngOnInit() {
    this.getPartners("");

    this._formControl.valueChanges
    .pipe(startWith(""), debounceTime(200))
    .subscribe((value: string | any) => {
        if (typeof value === "string") {
          this.applyFilter(value);
          this.cdr.detectChanges();
          this.getPartners(value);
        } else if (value && value.id) {
          this.selectedItem = true;
          this.formControl.setValue(value.resource.cpf);
        }
    });
  }
  
    getPartners(search: string) {
      const account = JSON.parse(localStorage.getItem("account") || "{}");
      const data = {
        bp_type_id: this.props.bp_type_id, 
        pageSize: 1000, 
        page: 1,
        search: search,
      }
      this.businessPartnerService
        .getBysearching(data)
        .subscribe((r: any) => {
          this.partners = r.data;
          console.log(this.partners);
          this.listOptions = r.data;
          this.cdr.detectChanges();

          if (!this.isFirstClick && this.inputElement) {
            this.inputElement.nativeElement.focus();
          }
          if(this.listOptions.length == 1 && account.resource.is_partner && !this._formControl?.value){
            this._formControl.setValue({ id: this.listOptions[0].id, name: this.listOptions[0].name });
          }
        });
    }

    onInputFocus() {
      if (this.isFirstClick) {
        this.isFirstClick = false;
      }
    }
  
    applyFilter(value: string) {
      if (typeof value === "string") {
        const ft = this.listOptions.filter((ft: any) =>
          ft.name.toLowerCase().includes(value.toLowerCase()),
        );
        this.listOptions = ft;
      }
    }
  
    resetFilter() {
      this.formControl.setValue("");
      this.formControl.defaultValue;
      this._formControl.defaultValue;
      this.ngModule = "";
      this.listOptions = [...this.partners];
    }
  
    formatCnpjCpf(document: string) {
      if (!document) {
        return "";
      }
  
      if (document.length === 14) {
        return document.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5",
        );
      } else if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      return document;
    }
  
    displaySelected(opt: any) {
      if (opt) {
        const cpf = this.formatCnpjCpf(opt.resource.cpf)
        this.formControl.setValue({ cpf: opt.resource.cpf });
        const ret = cpf + ' - ' + opt.name
        return opt && cpf ? ret : opt.resource.cpf;
      }
    }
  
    clear() {
      this.ngModule = "";
      this.formControl.setValue("");
      this.getPartners("");
    }
}
