import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
@Component({
  selector: "app-dialog-actions",
  templateUrl: "./dialog-actions.component.html",
  styleUrls: ["./dialog-actions.component.scss"],
})
export class DialogActionsComponent implements OnInit {
  registerOk = false;
  updateRegister = false;
  registerError = false;
  checkinOk = false;
  checkinError = false;
  createCheckinOk = false;
  checkinErrorBoard = false;

  constructor(
    public dialogRef: MatDialogRef<DialogActionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    console.log(this.data.item);
    if (this.data.item === "registerOk") {
      this.registerOk = true;
      this.registerError = false;
      this.checkinOk = false;
      this.checkinError = false;
      this.createCheckinOk = false;
      this.checkinErrorBoard = false;
      this.updateRegister = false;
    }
    if (this.data.item === "checkinOk") {
      this.registerOk = false;
      this.registerError = false;
      this.checkinOk = true;
      this.checkinError = false;
      this.createCheckinOk = false;
      this.checkinErrorBoard = false;
      this.updateRegister = false;
    }
    if (this.data.item === "updateRegister") {
      this.registerOk = false;
      this.registerError = false;
      this.checkinOk = false;
      this.checkinError = false;
      this.createCheckinOk = false;
      this.checkinErrorBoard = false;
      this.updateRegister = true;
    }
    if (this.data.item === "createCheckinOk") {
      this.registerOk = false;
      this.registerError = false;
      this.checkinOk = false;
      this.checkinError = false;
      this.createCheckinOk = true;
      this.checkinErrorBoard = false;
      this.updateRegister = false;
    }
    if (this.data.item === "checkinError") {
      this.registerOk = false;
      this.registerError = false;
      this.checkinOk = false;
      this.checkinError = true;
      this.createCheckinOk = false;
      this.checkinErrorBoard = false;
      this.updateRegister = false;
    }
    if (this.data.item === "checkinErrorBoard") {
      this.registerOk = false;
      this.registerError = false;
      this.checkinOk = false;
      this.checkinError = false;
      this.createCheckinOk = false;
      this.checkinErrorBoard = true;
      this.updateRegister = false;
    }
  }

  action(act: any) {
    this.dialogRef.close(act);
  }
}
