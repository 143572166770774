import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { VehicleTypeService } from 'src/app/account/shared/vehicle-type.service';

@Component({
  selector: 'formly-input-plate',
  templateUrl: './formly-input-plate.component.html',
  styleUrls: ['./formly-input-plate.component.scss']
})
export class FormlyInputPlateComponent extends FieldType<any> {
  vehicleInformation: any

  constructor(private vehicleTypeService: VehicleTypeService) {
    super();
  }

  ngOnInit() {
    this.formControl.valueChanges.pipe(
      debounceTime(500), // Aguarda 500ms após digitação
      distinctUntilChanged() // Evita chamadas repetidas com o mesmo valor
    ).subscribe((value: any) => {
      if (value && value.length == 7) { // Só busca se a placa tiver tamanho mínimo
        this.getVehicleByBoard(value);
      }
    });
  }

  getVehicleByBoard(board: string) {
    this.vehicleTypeService.vehicleTypeByBoard(board).subscribe((response: any) => {
      if (response.length >= 1) {
        this.vehicleInformation = response[0];
        const vehicleTypeControl = this.form.get('vehicle_type');

        if (vehicleTypeControl) {
          vehicleTypeControl.setValue(this.vehicleInformation.type_id);
          this.form.get('vehicle_type')?.disable();
          this.form.value['vehicle_type'] = this.vehicleInformation.type_id;
          this.form.value['vehicle_id'] = this.vehicleInformation.id;
        }
      } else {
        this.form.get('vehicle_type')?.enable();
        this.form.value['vehicle_type'] = null;
        this.form.value['vehicle_id'] = null;
      }
    });
  }
  

  messageError():string{
    const length = Object.keys(this.formControl.errors).length;

    if(length > 1) {
      return "Verifique o preenchimento"
    }

    if(this.formControl.hasError('required')) {
      return this.field.validation?.messages?.required || "Campo obrigatório";
    }
      
    if(this.formControl.hasError('pattern')) {
      return this.field.validation?.messages?.pattern || "Formato inválido";
    }      

    return "";
  }
}
