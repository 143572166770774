<mat-form-field [appearance]="to.appearance">
  <mat-label>{{ props.label }}</mat-label>
  <input
    type="text"
    [placeholder]="props.placeholder || ''"
    matInput
    [formControl]="formControl"
    [matAutocomplete]="auto"
  />

  <mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    [displayWith]="displayFn"
  >
    <mat-option *ngFor="let item of filteredList" [value]="item">
      {{ item.name || "" }}
    </mat-option>
  </mat-autocomplete>

  <button
    *ngIf="formControl.value"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="clear()"
    style="height: inherit; font-size: 0.9em"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
