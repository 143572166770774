import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AgendamentoService } from './../../../multiagendamento/shared/agendamento.service';
import { DialogConfirmComponent } from '../../../shared/dialog-confirm/dialog-confirm.component';
import { ScheduleWindowDialogComponent } from './../schedule-window-dialog/schedule-window-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-schedule-window',
  templateUrl: './schedule-window.component.html',
  styleUrls: ['./schedule-window.component.scss']
})
export class ScheduleWindowComponent implements OnInit {
  windows: any[] = [];
  @Input() hiddenElements:any;

  constructor(
    private dialog: MatDialog,
    private agendamentoService: AgendamentoService,
    private translate: TranslateService

  ) { }

  ngOnInit() {
    this.getScheduleWindows();
  }

  addEdit(item?:any) {
    const dialogRef = this.dialog.open(ScheduleWindowDialogComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: {...item},
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.getScheduleWindows();
    });
  }

  getScheduleWindows() {
    this.agendamentoService.indexSchedulingWindow().subscribe((r: any) => {
      this.windows = r.data;
    });
  }


  formatTimeSlot(minutes:string) {
    const transform: { [key: string]: string } = {
      "5": "5 " + this.translate.instant("Time.minutes", {Default: "minutos"}),
      "10": "10 " + this.translate.instant("Time.minutes", {Default: "minutos"}),
      "15": "15 " + this.translate.instant("Time.minutes", {Default: "minutos"}),
      "20": "20 " + this.translate.instant("Time.minutes", {Default: "minutos"}),
      "25": "25 " + this.translate.instant("Time.minutes", {Default: "minutos"}),
      "30": "30 " + this.translate.instant("Time.minutes", {Default: "minutos"}),
      "40": "40 " + this.translate.instant("Time.minutes", {Default: "minutos"}),
      "45": "45 " + this.translate.instant("Time.minutes", {Default: "minutos"}),
      "60": "1 " + this.translate.instant("Time.hour", {Default: "hora"}),
      "90": "1:30 " + this.translate.instant("Time.hours", {Default: "horas"}),
      "120": "2 " + this.translate.instant("Time.hours", {Default: "horas"}),
      "150": "2:30 " + this.translate.instant("Time.hours", {Default: "horas"}),
      "180": "3 " + this.translate.instant("Time.hours", {Default: "horas"}),
      "210" : "3:30 " + this.translate.instant("Time.hours", {Default: "horas"}),
      "240": "4 " + this.translate.instant("Time.hours", {Default: "horas"}),
      "270": "4:30 " + this.translate.instant("Time.hours", {Default: "horas"}),
      "300": "5 " + this.translate.instant("Time.hours", {Default: "horas"}),
      "330": "5:30 " + this.translate.instant("Time.hours", {Default: "horas"}),
      "360": "6 " + this.translate.instant("Time.hours", {Default: "horas"}),
      "480": "8 " + this.translate.instant("Time.hours", {Default: "horas"}),
      "600": "10 " + this.translate.instant("Time.hours", {Default: "horas"}),
      "720": "12 " + this.translate.instant("Time.hours", {Default: "horas"})
    }

    return transform[minutes];
  }

  delete(id: number) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: this.translate.instant("Business_model.attention_upper", {Default: "ATENÇÃO"}) + '!',
        message: this.translate.instant("Business_model.delete_window_schedule", {Default: "Tem certeza que deseja APAGAR a janela de agendamento?"})
      }
    });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.agendamentoService.deleteSchedulingWindow(id).subscribe((r: any) => {
          if(!r.error) {
            this.getScheduleWindows();
          }
        });
      }
    });
  }

}
