<mat-form-field [appearance]="props.appearance">
    <mat-label>{{ props.label || "" }}</mat-label>
    <mat-select
      [formControl]="formControl"
      [required]="props.required || false"
      [disabled]="props.disabled || true"
      (selectionChange)="to.change && to.change(field, $event)"
    >
      <mat-option  *ngFor="let item of list" [value]="item.id">
        {{ item.name }}
      </mat-option>
    </mat-select>
</mat-form-field>
  