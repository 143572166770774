import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {

  constructor(private http: HttpClient) {}
  
  routesList(name: any = ''): Observable<any> {
    const url = `${environment.apiUrl}/routes`;
    const httpParams = Object.assign({name});
    const params = new HttpParams({ fromObject: httpParams });

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error")),
    );
  }
}

