import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { GenericService } from '../../generic.service';
import { RegistrationService } from '../../registration.service';
import { SupportDataService } from '../../support-data.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { YardTaskService } from 'src/app/account/shared/yard-task.service';
import { DriversService } from 'src/app/drivers/shared/drivers.service';
import { Observable, of, tap } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { CheckinService } from 'src/app/checkin/shared/checkin.service';


@Component({
  selector: 'app-add-dialog-tasks',
  templateUrl: './add-dialog-tasks.component.html',
  styleUrls: ['./add-dialog-tasks.component.scss']
})
export class AddDialogTasksComponent implements OnInit {

  task_types: any = [];
  is_valet: number = 0;
  columnsSchema: any[] = [];
  transformData: any = {};
  transformMethods: any = {}
  displayedColumns: string[] = [];
  dataSource: any[] = [];
  truckerDrivers: any[] = [];
  task: any = {};
  valetsDrivers: [] = [];
  displayedDrivers: any[] = [];
  filter: Observable<any>;
  driver_id: number;
  driver_search: any;
  myControl = new FormControl();

  form = this.formBuilder.group({
    name: [{ value: "", disabled: true }, Validators.required],
    observation: [""],
    task_model_id: [""],
    search: [""],
    valetsOnly: [false],
    driver_id: [{ value: "" }]
  });

  display_message = false;
  message = '';
  user: any;
  showTable = false;
  listOptions: any[] = [];
  ngModule: string;

  constructor(
    private genericService: GenericService,
    private supportDataService: SupportDataService,
    private checkinService: CheckinService,
    private yardTaskService: YardTaskService,
    public formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<any>,
    private driversService: DriversService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.getTaskTypesList();
    this.observeValetsOnly();
    this.getDrivers();

    if (this.myControl.value) {
      this.getDriverById(this.data?.driver_id);
    }


    // se for edição, buscar os dados da tarefa para preencher os inputs
    if (this.data?.item?.id) {
      this.getData();
    }

    // verifica se o modelo da tarefa é "outro", se for, desbloqueia o input "name"
    this.form.get('task_model_id')?.valueChanges.subscribe((value) => {
      if (value === 4) {
        this.form.get('name')?.enable();
      } else if (value != 4 && this.form.get('name') != undefined) {
        this.form.get('name')?.setValue(undefined)
        this.form.get('name')?.disable();

      }
    });

    this.myControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(""),
        map((value) => value),
      )
      .subscribe((value) => {
        if (value) {
          this.getDrivers(value);
          this.cdr.detectChanges();
        } else {
          this.resetFilter();
        }
      });

  }

  getData() {
    const options = {
      search: false,
      paginate: false,
      pageSize: 0,
      page: 1,
      orderBy: '',
      sortedBy: '',
    };

    this.yardTaskService.get(this.data?.item?.id, options).subscribe((r: any) => {
      this.task = r.resource;
      if (r.resource) {
        this.form.patchValue({
          name: r.resource?.name,
          observation: r.resource?.observation,
          driver_id: r.resource?.driver_id,
          valetsOnly: r.resource?.valetsOnly,
          task_model_id: r.resource?.task_model_id
        });

        // Busca o motorista pelo ID
        if (r.resource.driver_id) {
          this.getDriverById(r.resource.driver_id);
        }

        // Verifica se o valor do modelo de tarefa é "outros" e desbloqueia o input "name"
        if (r.resource.task_model_id === 4) {
          this.form.get('name')?.enable();
        } else if (r.resource.task_model_id != 4 && this.form.get('name') != undefined) {
          this.form.get('name')?.setValue(undefined)
          this.form.get('name')?.disable();

        }
      }
    });
  }

  submit() {
    const submitData = { ...this.form.value }

    delete submitData.search;
    if (!submitData.task_model_id) {
      delete submitData.task_model_id;
    }

    const updatedResource = Object.assign({}, this.task, submitData);
    const data: any = {
      resource: updatedResource
    }

    if (this.data.hasOwnProperty('origin')) {
      if (this.data.origin === 'task_id') {
        data.checkin_id = this.data?.item?.checkin_id
        data.id = this.data?.item?.id
      } else {
        const origin = this.data.origin as keyof typeof data;
        data[origin] = this.data.id;
      }
    }

    if (data.id || this.data?.item?.resource?.task_id) {
      if (this.data.item?.resource?.task_id) {
        data.id = this.data.item.resource.task_id;
      } else {
        data.id = this.data.item.id;
      }

      if(data.resource.task_model_id != 4){
      data.resource.name = null
      }
      this.yardTaskService.update(data).subscribe((r: any) => {
        this.dialogRef.close();
      });
    } else {
      this.yardTaskService.create(data).subscribe((r: any) => {

        // validação para checar se é uma tarefa criada apartir do modulo de jornadas
        if (this.data.item.resource) {
          const checkinData = { ...this.data.item.resource };
          const checkinResource = Object.assign({}, { task_id: r.task_id, checkin_id: this.data.item.id }, checkinData);

          //atualiza o resource da jornada, atribuindo o task_id
          this.checkinService.updateResource(checkinResource).subscribe(() => {
            this.dialogRef.close();
          });;
        } else {
          this.dialogRef.close();
        }
      });
    }

  }

  cancel() {
    this.dialogRef.close();
  }

  observeValetsOnly() {
    this.form.get('valetsOnly')?.valueChanges.subscribe((isValet: boolean) => {
      // atualiza o valor de is_valet
      this.is_valet = isValet ? 1 : 0;

      // refaz a busca de motoristas com base no novo valor de is_valet
      this.getDrivers();
    });
  }

  getDriverById(driverId: number) {
    this.driversService.show(driverId).subscribe((res: any) => {
      if (res) {
        const driver = res;
        // define o valor do campo de pesquisa com o objeto completo do motorista
        this.myControl.setValue(driver);
        // atualiza o driver_id no formulário
        this.form.patchValue({ driver_id: driver.id });
      }
    });
  }

  // applyFilter(value: string) {
  //   if (typeof value === "string") {
  //     const ft = this.listOptions.filter((ft: any) =>
  //       ft.name.toLowerCase().includes(value.toLowerCase()),
  //     );
  //     this.listOptions = ft;
  //   }
  // }

  resetFilter() {
    this.myControl.setValue("");
    this.myControl.defaultValue;
    this.ngModule = "";
    this.listOptions = [...this.displayedDrivers];
  }


  // getValetsDrivers() {
  //   this.driversService.getValetsDrivers()
  //     .pipe(
  //       tap((response: any) => {
  //         this.displayedDrivers = response;
  //         this.valetsDrivers = response;
  //       })
  //     )
  //     .subscribe();
  // }

  getDrivers(searchValue?: string) {
    const options = {
      is_valet: this.is_valet == 1 ? true : false, // usa o valor atualizado de is_valet
      search: searchValue ? searchValue : this.driver_search?.driver_id || this.driver_id
    };

    // se search for undefined tirar das options
    if (options.search === undefined) {
      delete options.search;
    }

    this.driversService.getDrivers(options).subscribe((res: any) => {
      this.displayedDrivers = res;
      this.listOptions = res; // atualiza a lista de opções para o autocomplete
    });
  }

  displayFn(driver: any): string {
    if (driver) {
      this.form.patchValue({ driver_id: driver.id })
    }
    return driver && driver.name ? driver.name : '';
  }



  getTaskTypesList() {
    this.supportDataService.generic({ name: 'task_types' }).subscribe((r: any) => {
      this.task_types = r.data.resource;
    });
  }
}
