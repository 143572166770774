import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VehicleTypeService {

  constructor(private http: HttpClient) { }

  vehicleTypeList(): Observable<any> {
    const url = `${environment.apiUrl}/vehicles-list`;
    const httpParams = Object.assign({});
    const params = new HttpParams({ fromObject: httpParams });

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error")),
    );
  }

  vehicleTypeByBoard(board: any): Observable<any> {
    const url = `${environment.apiUrl}/vehicles-by-board`;
    const httpParams = Object.assign({board});
    const params = new HttpParams({ fromObject: httpParams });

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error")),
    );
  }

  vehicleAdd(data: any): Observable<any> {
    const url = `${environment.apiUrl}/vehicles-add`;

    return this.http.post<any>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error"))
    );
  }
}
