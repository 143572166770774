<div class="container" *ngIf="registerOk">
  <div class="item-on-center">
    <img class="logo" src="assets/img/ok.png" height="157pt" />
  </div>
  <p class="instruction">
    Cadastro realizado<br />
    com sucesso!
  </p>
  <div class="buttons item-on-center">
    <button
      mat-raised-button
      class="go-checkin"
      (click)="action('checkinPage')"
    >
      OK
    </button>
  </div>
</div>
<div class="container" *ngIf="updateRegister">
  <div class="item-on-center">
    <img class="logo" src="assets/img/ok.png" height="157pt" />
  </div>
  <p class="instruction">
    Atualize seu cadastro<br />
    para continuar!
  </p>
  <div class="buttons item-on-center">
    <button
      mat-raised-button
      class="go-checkin"
      (click)="action('updateRegisterPage')"
    >
      OK
    </button>
  </div>
</div>
<div class="container" *ngIf="checkinError">
  <div class="item-on-center">
    <img class="logo" src="assets/img/error.png" height="157pt" />
  </div>
  <p class="message">{{ data.msg || "" }}</p>
  <div class="buttons item-on-center">
    <button
      mat-raised-button
      class="go-checkin"
      (click)="action('registerPage')"
    >
      FAZER CADASTRO
    </button>
  </div>
  <br />
  <br />
  <div class="buttons item-on-center">
    <button mat-raised-button class="go-register" (click)="action('dismiss')">
      VOLTAR
    </button>
  </div>
</div>
<div class="container" *ngIf="checkinErrorBoard">
  <div class="item-on-center">
    <img class="logo" src="assets/img/error.png" height="157pt" />
  </div>
  <p class="message">{{ data.msg || "" }}</p>
  <br />
  <br />
  <div class="buttons item-on-center">
    <button mat-raised-button class="go-register" (click)="action('close')">
      VOLTAR
    </button>
  </div>
</div>
<div class="container" *ngIf="checkinOk">
  <div class="item-on-center">
    <img class="logo" src="assets/img/ok.png" height="157pt" />
  </div>
  <p class="message">{{ data.msg || "" }}</p>
  <div class="buttons item-on-center">
    <button
      mat-raised-button
      class="go-checkin"
      (click)="action('checkinPage2')"
    >
      PRÓXIMO PASSO
    </button>
  </div>
</div>
<div class="container" *ngIf="createCheckinOk">
  <div class="item-on-center">
    <img class="logo" src="assets/img/ok.png" height="157pt" />
  </div>
  <p class="message">{{ data.msg || "" }}</p>
  <div class="buttons item-on-center">
    <button
      mat-raised-button
      class="go-create-checkin"
      (click)="action('dismiss')"
    >
      VOLTAR
    </button>
  </div>
</div>
