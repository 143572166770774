import { environment } from 'src/environments/environment';
import { AgendamentoService } from './../../../multiagendamento/shared/agendamento.service';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AutomationDialologComponent } from '../automation-dialolog/automation-dialolog.component';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-automation',
  templateUrl: './automation.component.html',
  styleUrls: ['./automation.component.scss']
})
export class AutomationComponent implements OnInit {
  windows: any = [];
  @Input() hiddenElements: any;
  listStatus: any = [];
  listStatusName: any = [];
  disabledButton: boolean = false;
  isEmpty: boolean = true;

  constructor(
    private dialog: MatDialog,
    private agendamentoService: AgendamentoService,
    private supportDataService: SupportDataService,
    private cdr: ChangeDetectorRef,
    public translate: TranslateService,
  ) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.agendamentoService.getSystemClientAutomation().subscribe((r: any) => {
      this.windows = r;
      if (this.windows) {
        this.getStatus();
        if ((this.windows.environment?.automate_schedule_to_no_show || this.windows.environment?.automate_schedule_to_no_show == false) &&
          (this.windows.environment?.automate_schedule_to_delayed || this.windows.environment?.automate_schedule_to_delayed == false)) {
          this.disabledButton = true;
        } else {
          this.disabledButton = false;
        }

        if (!this.windows.environment?.automate_schedule_to_no_show && !this.windows.environment?.automate_schedule_to_delayed ){
          this.isEmpty = true;
        }else{
          this.isEmpty = false;
        }
      }
    });
  }

  getStatus() {
    this.supportDataService.scheduleStatus().subscribe((r: any) => {
      this.listStatus = r.data.resource;
      this.formatStatus('no_show');
      this.formatStatus('delayed');
      this.cdr.detectChanges();
    });
  }

  formatStatus(item: string) {
    let data;
    if (this.windows.environment[`automate_schedule_to_${item}_when_status`]) {
      for (let i = 0; i < this.windows.environment[`automate_schedule_to_${item}_when_status`]?.length; i++) {
        const status = this.listStatus.find((status: any) => status.id == this.windows.environment[`automate_schedule_to_${item}_when_status`][i]);
        data = data ? data + `, ` + status.name : status.name;
      }
      this.listStatusName = {
        ...this.listStatusName,
        [`automate_schedule_to_${item}_when_status`]: data,
        [`automate_schedule_to_${item}_set_status`]: this.listStatus.find((status: any) => status.id == this.windows.environment[`automate_schedule_to_${item}_set_status`]).name
      }
    }
  }

  addEdit(item?: string) {
    let data = {}
    if (item) {
      data = this.formatEditObject(item);
    }
    const dialogRef = this.dialog.open(AutomationDialologComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: {
        ...data,
        optionDelayed: this.windows.environment?.automate_schedule_to_delayed,
        optionNoShow: this.windows.environment?.automate_schedule_to_no_show
      }
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.getData();
    });
  }

  delete(item: any) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title:
          this.translate.instant("Business_model.attention_upper", {
            Default: "ATENÇÃO",
          }) + "!",
        message: this.translate.instant(
          "Business_model.delete_window_automation",
          {
            Default:
              "Tem certeza que deseja APAGAR essa automação",
          },
        ),
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        delete this.windows.environment[`automate_schedule_to_${item}`];
        delete this.windows.environment[`automate_schedule_to_${item}_when_status`];
        delete this.windows.environment[`automate_schedule_to_${item}_set_status`];
        delete this.windows.environment[`automate_schedule_to_${item}_after`];
      }
      this.agendamentoService.updateSystemClient(this.windows).subscribe((r: any) => {
        this.getData();
      });
    });
  }

  formatEditObject(item: string) {
    let data = {
      type_automation: item,
      [`automate_schedule_to_${item}`]: this.windows.environment[`automate_schedule_to_${item}`],
      [`automate_schedule_to_${item}_when_status`]: this.windows.environment[`automate_schedule_to_${item}_when_status`],
      [`automate_schedule_to_${item}_set_status`]: this.windows.environment[`automate_schedule_to_${item}_set_status`],
      [`automate_schedule_to_${item}_after`]: this.windows.environment[`automate_schedule_to_${item}_after`]
    };
    return data;
  }

}
