<mat-toolbar class="checkin-tb">
  <button
    mat-icon-button
    aria-label="Back"
    class="back-button"
    *ngIf="!firstPage"
    (click)="back2Page()"
  >
    <mat-icon class="back-icon">navigate_before</mat-icon>
  </button>
  <div class="item-on-center">
    <img
      class="logo"
      src="assets/img/logo-trackage-horizontal-white-new.png"
      height="63.69pt"
    />
  </div>
</mat-toolbar>
<div class="container" *ngIf="firstPage">
  <div class="item-on-center">
    <img src="assets/img/mapa.png" class="mapa" />
  </div>
  <p class="instruction">
    Olá Motorista! Selecione<br />
    uma das opções abaixo:
  </p>
  <div class="buttons item-on-center">
    <button
      mat-raised-button
      class="go-checkin"
      (click)="changePage('firstPage', 'checkinPage')"
    >
      FAZER CHECK IN
    </button>
  </div>
  <!-- <br>
  <div class="buttons item-on-center">
    <button mat-raised-button class="go-journey" (click)="changePage('firstPage', 'journeyPage')">ACESSAR
      JORNADA</button>
  </div> -->
  <br />
  <div class="buttons item-on-center">
    <button
      mat-raised-button
      class="go-register"
      (click)="changePage('firstPage', 'registerPage')"
    >
      CADASTRAR
    </button>
  </div>
</div>

<div class="container" *ngIf="checkinPage">
  <div *ngIf="checkinTel">
    <p class="instruction">Check in</p>
    <p class="instruction2">
      Utilize seu número telefônico e sua<br />
      senha para fazer o check in.
    </p>
    <form [formGroup]="driverLoginTel" class="form-checkin item-on-center">
      <mat-form-field>
        <mat-label>Número do telefone</mat-label>
        <input
          matInput
          formControlName="phone"
          [textMask]="{ mask: maskUtil.maskPhone(11) }"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Senha</mat-label>
        <input matInput formControlName="password" type="password" />
      </mat-form-field>
    </form>
    <div class="buttons item-on-center">
      <button
        mat-raised-button
        class="go-checkin"
        (click)="checkDriverTel()"
        [disabled]="disableBtnCheckin"
      >
        FAZER CHECK IN
      </button>
    </div>
  </div>
  <div *ngIf="checkinCpf">
    <p class="instruction">Check in</p>
    <p class="instruction2">
      Utilize seu CPF e sua senha <br />para fazer o check in.
    </p>
    <form [formGroup]="driverLoginCpf" class="form-checkin item-on-center">
      <mat-form-field>
        <mat-label>CPF</mat-label>
        <input
          matInput
          formControlName="cpf"
          [textMask]="{ mask: maskUtil.maskCPF() }"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Senha</mat-label>
        <input matInput formControlName="password" type="password" />
      </mat-form-field>
    </form>
    <div class="buttons item-on-center">
      <!-- <button mat-raised-button class="go-checkin" (click)="checkDriverCpf()"
        [disabled]="disableBtnCheckin">FAZER CHECK IN </button> -->
      <button mat-raised-button class="go-checkin" (click)="checkDriverCpf()">
        FAZER CHECK IN
      </button>
    </div>
  </div>
</div>

<div class="container" *ngIf="checkinPage2">
  <p class="instruction">Jornada do Motorista</p>
  <p class="instruction2">Olá {{ driverLogged.name }}!</p>
  <div *ngIf="driverLogged.checkin">
    <p class="instruction2-green">Você já possui uma jornada ativa</p>
    <p class="instruction2">Altere os dados da sua jornada</p>
  </div>
  <div *ngIf="!driverLogged.checkin">
    <p class="instruction2">Insira os dados da sua nova jornada</p>
  </div>
  <ng-container *ngIf="checkinType !== 'pedido'">
    <form [formGroup]="checkin" class="form-checkin item-on-center">
      <div *ngIf="this.user.department_id.length > 1">
        <mat-form-field>
          <mat-label>Departamento</mat-label>
          <mat-select formControlName="department_id">
            <mat-option
              *ngFor="let department of departments"
              [value]="department.id"
              (click)="getOperations(department.id)"
            >
              {{ department.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-form-field>
        <mat-label>Operação</mat-label>
        <mat-select formControlName="operation">
          <mat-option
            *ngFor="let operation of operations"
            [value]="operation.id"
          >
            {{ operation.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Placa Cavalo</mat-label>
        <input
          matInput
          formControlName="board_horse"
          style="text-transform: uppercase"
          mask="AAAAAAA"
          onkeyup="this.value = this.value.toUpperCase();"
          [readonly]="board_readonly"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="cartSearchDialog('Cavalo')"
          *ngIf="search_board_show"
        >
          <mat-icon class="btn-search">pageview</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field *ngIf="showBoardCart">
        <mat-label>Placa Carreta</mat-label>
        <input
          matInput
          formControlName="board_cart"
          style="text-transform: uppercase"
          mask="AAAAAAA"
          onkeyup="this.value = this.value.toUpperCase();"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="cartSearchDialog('Carreta')"
          *ngIf="search_cart_show"
          [disabled]="cart_readonly"
        >
          <mat-icon class="btn-search">pageview</mat-icon>
        </button>
      </mat-form-field>
      <!-- <mat-form-field style="display: none;">
        <mat-label>Departamento</mat-label>
        <input matInput formControlName="department_id">
      </mat-form-field> -->
    </form>
    <div class="buttons item-on-center">
      <button
        mat-raised-button
        class="go-checkin"
        (click)="createOrUpdateJourney()"
        [disabled]="disableBtnCheckin || !checkin.valid"
      >
        {{ driverLogged.checkin ? "ATUALIZAR" : "SALVAR" }}
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="checkinType === 'pedido'">
    <form [formGroup]="checkinPedido" class="form-checkin item-on-center">
      <mat-form-field>
        <mat-label>Pedido de compra</mat-label>
        <input matInput formControlName="pedido_compra" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Quantidade de ajudantes</mat-label>
        <input matInput formControlName="qtd_ajudantes" />
      </mat-form-field>
    </form>
    <div class="buttons item-on-center">
      <button
        mat-raised-button
        class="go-checkin"
        (click)="createOrUpdateJourney()"
        [disabled]="disableBtnCheckin || !checkinPedido.valid"
      >
        {{ driverLogged.checkin ? "ATUALIZAR" : "SALVAR" }}
      </button>
    </div>
  </ng-container>
</div>
<div class="container" *ngIf="registerPage">
  <p class="instruction">Cadastro</p>
  <form [formGroup]="register" class="form-register item-on-center">
    <mat-form-field>
      <mat-label>Nome</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>CNH</mat-label>
      <input matInput formControlName="cnh" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>CPF</mat-label>
      <input
        matInput
        formControlName="cpf"
        [textMask]="{ mask: maskUtil.maskCPF() }"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Número do telefone</mat-label>
      <input
        matInput
        formControlName="phone"
        [textMask]="{ mask: maskUtil.maskPhone(11) }"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Transportadora</mat-label>
      <mat-select formControlName="carrier_id">
        <ngx-mat-select-search
          [formControl]="carrierFilter"
          placeholderLabel="Buscar transportadora"
          noEntriesFoundLabel="Nenhum resultado encontrado"
        ></ngx-mat-select-search>
        <mat-option *ngFor="let carrier of carriers" [value]="carrier.id">
          {{ carrier.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Senha</mat-label>
      <input matInput formControlName="password" type="password" />
    </mat-form-field>
  </form>
  <div class="buttons item-on-center">
    <button mat-raised-button class="go-checkin" (click)="createDriver()">
      CADASTRAR
    </button>
  </div>
</div>
<div class="container" *ngIf="updateRegisterPage">
  <p class="instruction">Atualize seu Cadastro</p>
  <form [formGroup]="updateRegister" class="form-register item-on-center">
    <mat-form-field>
      <mat-label>Nome</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>CNH</mat-label>
      <input matInput formControlName="cnh" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>CPF</mat-label>
      <input
        matInput
        formControlName="cpf"
        [textMask]="{ mask: maskUtil.maskCPF() }"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Número do telefone</mat-label>
      <input
        matInput
        formControlName="phone"
        [textMask]="{ mask: maskUtil.maskPhone(11) }"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Transportadora</mat-label>
      <mat-select formControlName="carrier_id">
        <ngx-mat-select-search
          [formControl]="carrierFilter"
          placeholderLabel="Buscar transportadora"
          noEntriesFoundLabel="Nenhum resultado encontrado"
        ></ngx-mat-select-search>
        <mat-option *ngFor="let carrier of carriers" [value]="carrier.id">
          {{ carrier.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <mat-form-field>
      <mat-label>Senha</mat-label>
      <input matInput formControlName="password" type="password" />
    </mat-form-field> -->
  </form>
  <div class="buttons item-on-center">
    <button mat-raised-button class="go-checkin" (click)="updateDriver()">
      ATUALIZAR CADASTRO
    </button>
  </div>
</div>
<div class="footer">
  <div class="item-on-center">
    <img
      class="logo"
      src="assets/img/logo-trackage-green-black-new.png"
      height="36pt"
      style="padding-top: 60px"
    />
  </div>
</div>
